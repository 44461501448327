import { Campus } from '@models/campus/campus';
import { IMedia } from '@models/company/company';

/**
 * Location model which describe a school or a company for a particular waypoint
 */
export abstract class Location {
  /**
   * Get the list of the cities where the location is present in a string list
   */
  public get cities(): string[] {
    if (!this.campuses.length) {
      return this.city ? [this.city] : [];
    }

    return this.campuses.reduce((cities, campus) => {
      if (campus.city && !cities.includes(campus.city)) {
        if (cities) return [...cities, campus.city];

        return [campus.city];
      }

      return cities;
    }, []);
  }

  public get hasMedia(): boolean {
    return !!this.media?.url;
  }

  /**
   * Location model which describe a school or a company for a particular waypoint
   *
   * @param userCount The number of users who have been in the location
   *                  For schools, it is the number of users who are studying at or have graduated by this school
   *                  For companies, it is the number of user who are or have been working in the company
   * @param city The name of the city where the location is located.
   *             Used if the location is located in only one city
   * @param campuses A list of cities where the company is located.
   *                 Used if the company has numerous cities
   * @param distance The user (or filter) distance from the location
   * @param application (Optional) The location application if the location is a Millionroads client
   */
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly userCount: number,
    protected readonly city: string,
    public readonly campuses: Campus[],
    public readonly distance: number,
    public readonly media: IMedia,
    public readonly application?: string,
    public readonly jobUrl?: string
  ) {}
}

export enum LocationType {
  Company = 'company'
}
