import { ApplicationName } from '@models/context/application-name';
import { Product } from '@models/context/product';

export const environment = {
  production: true,
  realProduction: true,
  prodDatabase: true,
  keycloak: {
    issuer: 'https://id.millionroads.com/auth/realms/humanroads',
    clientId: 'gps-prod',
    adminClientId: 'backoffice-prod',
    showDebugInformation: false
  },
  analyticsEnvironment: 'prod',
  adventureUri: '/apis/adventure',
  poiUri: '/apis/poi',
  hostSuffix: {
    [Product.Explore]: 'explore.millionroads.com',
    [Product.Connect]: 'connect.millionroads.com',
    [ApplicationName.MyRoad]: 'myroad.app',
    [ApplicationName.CmqBtpNumerique]: 'batistonparcours.campus-btp-numerique.fr',
    [Product.Show]: 'salon.millionroads.com',
    [ApplicationName.RegionSud]: 'region-sud.explore.millionroads.com'
  },
  protocol: 'https://'
};
