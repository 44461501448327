import { Waypoint } from '@clients/adventure/bean/waypoint';
import { Step } from '@clients/poi/poi';
import { Cause } from '@features/causes/causes.models';
import { Campus } from '@models/campus/campus';
import { Gender } from '@models/gender/gender';
import { Location } from '@models/location/location';
import { ApiLocation } from '@models/location/location.api';
import { IPartner } from '@models/poi/human-poi/human-poi.api';
import { Observable, map } from 'rxjs';

/**
 * Company model which describe a company for a particular waypoint
 */
export class Company extends Location {
  public static build(apiCompany: ApiLocation): Company {
    return new Company(
      apiCompany.id,
      apiCompany.name,
      apiCompany.userCount,
      apiCompany.city,
      apiCompany.campuses,
      apiCompany.distance,
      apiCompany.media,
      apiCompany.application,
      apiCompany.jobUrl
    );
  }

  public static getJobUrl(
    selectJobTeaserPrefix: Observable<string>,
    jobUrl: string
  ): Observable<string> {
    return selectJobTeaserPrefix.pipe(
      map(jobTeaserPrefix => {
        const splitUrl = jobUrl.split('jobteaser.com');

        if (jobTeaserPrefix && splitUrl?.length > 1) {
          return `https://${jobTeaserPrefix}.jobteaser.com${splitUrl[1]}`;
        }

        return jobUrl;
      })
    );
  }
}

/**
 * Company from "company uri" (not in a waypoint context)
 */
export interface ICompany {
  id: string;
  name: string;
  logo: string;
  description: string;
  userCount: number;
  campuses: Campus[]; // Interface
  images: string[];
  videos: string[];
  media: IMedia;
  application: string;
  city: string;
  email: string;
  distance?: number;
  causes?: Cause[];
  website?: string;
  jobUrl?: string;
}

export interface IHuman {
  id: string;
  username: string; // email adress
  name: string;
  gender: Gender;
  steps: Step[];
  media: IMedia;
  type: string;
  provider: string;
  anonym: boolean;
  ambassadorLogo?: string;
  firstname?: string;
  socialUrl?: string;
  socialLabel?: string;
  partners?: IPartner[];
}

export interface GmapDto {
  media: IMedia;
  geolocation?: { latitude: number; longitude: number };
  count?: number;
}

export interface IMedia {
  url: string;
  type: MediaTypeEnum;
  mediaOfResumeNode: boolean;
  name?: string;
  description?: string;
  waypoint?: Waypoint;
}

export enum MediaTypeEnum {
  VIDEOASK = 'VIDEOASK',
  IFRAME = 'IFRAME'
}

export interface ILocation {
  name: string;
  city: string;
  id: string;
  country: string;
  countryCode: string;
  applications: string[];
}

export enum NomenclatureEnum {
  alumni = 'alumni',
  student = 'student',
  user = 'user'
}
